class StopScroll {
    constructor() {
        this.scrollTopPositionInit = 0;
        this.stopScrollIsActive = false;
    }

    scroll(flag) {
        const that = this;
        const body = document.querySelector("body"),
            html = document.querySelector("html");

        if (flag) {
            html.setAttribute(
                "style",
                `
                    position:static; 
                `
            );

            if (that.stopScrollIsActive) {
                window.scrollTo(0, that.scrollTopPositionInit);
                that.stopScrollIsActive = false;
            }
        } else {
            that.scrollTopPositionInit = window.pageYOffset;
            html.setAttribute(
                "style",
                `
                    width: 100%;
                    position:fixed; 
                    top: ${that.scrollTopPositionInit * -1}px;
                `
            );

            that.stopScrollIsActive = true;
        }
    }
}

let stopScroll;

export default stopScroll = new StopScroll();
