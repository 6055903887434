import $ from 'jquery';
import 'air-datepicker';
import './jquery.inputmask';
import stopScroll from './stopScroll';
import QrScanner from './qr-scanner.min.js';
QrScanner.WORKER_PATH = './media/js/qr-scanner-worker.min.js';

function checkTouchDevice() {
    return !!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|WindowsPhone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
}
checkTouchDevice() ? '' : $('html').addClass('notTouch');


function toNailFooter() {
    const footer = document.querySelector(".footer");
    const body = document.querySelector("body");

    footer.style.position = "absolute";
    footer.style.left = "0px";
    footer.style.right = "0px";
    footer.style.bottom = "0px";
    body.style.paddingBottom = `${footer.offsetHeight}px`;
    body.style.minHeight = "100vh";

    $('.content').css('min-height', `calc(100vh - ${footer.offsetHeight}px)`);
}

window.openPopup = (target, openManual = false) => {
    if (target == 'reg') {
        $('.popup[data-target="auth"]').hide();
        $('.popup[data-target="system"]').hide();
    } else if (target == 'auth') {
        $('.popup[data-target="reg"]').hide();
        $('.popup[data-target="system"]').hide();
    }

    if (target == "check") {
        if (!$('html.notTouch').length && openManual != true) {
            target = "qr";
        } else {
            closePopup('qr');
        }
    }

    $('.popups').css('display', 'flex');
    $('.popup[data-target="' + target + '"]').show();

    stopScroll.scroll(false)
}

window.closePopup = (target) => {
    $('.popup[data-target="' + target + '"]').hide();

    // clearpopupInputs(target);

    if (target = "qr" && qrScanner != null) {
        qrKill();
    }

    if ($('.popup:visible').length == 0) {
        closePopupAll()
    }
}

window.closePopupAll = () => {
    $('.popup').hide();
    $('.popups').hide();

    stopScroll.scroll(true)

    if (qrScanner != null) {
        qrKill();
    }

    $('.popup').each(function(index, el) {
        var that = $(el);
        var target = that.attr('data-target');
        // clearpopupInputs(target);
    })
}

function clearpopupInputs(target) {
    var that = $('.popup[data-target="' + target + '"]');
    
    that.find('input[type="text"]').val('');
    that.find('input[type="text"]').text('');

    that.find('input[type="password"]').val('');
    that.find('input[type="password"]').text('');
}

window.openPopupSystem = (title, text) => {
    $('.popup[data-target="system"]').find('.popup__title').text(title);
    $('.popup[data-target="system"]').find('.popup__text').html(text);

    openPopup('system');
}

function prize_filter() {
    
    var pagelink = window.location.protocol+'//'+window.location.host+window.location.pathname;
    window.location = pagelink+'?prize='+$('#prize_filter').val()+'&date='+$('#date_filter').val();
}

function winners_filter_prize(id) {
    var pagelink = window.location.protocol+'//'+window.location.host+window.location.pathname;
    window.location = pagelink+'?prize='+id+'&date='+$('#date_filter').val();
}

function winners_filter_date() {
    var pagelink = window.location.protocol+'//'+window.location.host+window.location.pathname;
    window.location = pagelink+'?prize=&date='+$('#date_filter').val();
}


window.startSmsTimer = () => {
    $('.hiddenUntilTimer').hide();
    $('.backTimer').show();

    var timeLeft = 600;

    var smsInterval = setInterval(function() {
        $('.backTimer .seconds').text(timeLeft);

        if (timeLeft <= 0) {
            clearInterval(smsInterval);
            $('.backTimer').hide();
            $('.hiddenUntilTimer').show();
        }

        timeLeft = timeLeft - 1;
    }, 1000)
}



























$(document).ready(function() {
    toNailFooter();

    // $('#dateInput0').val(new Date());
  
    $('.dateMask').inputmask("datetime",{
        inputFormat: "dd.mm.yyyy HH:MM",
        placeholder: "дд.мм.гггг чч:мм",
        leapday: "-02-29",
        alias: "tt.mm.jjjj"
    });

    $('.dateOnlyMask').inputmask("datetime",{
        inputFormat: "dd.mm.yyyy",
        placeholder: "дд.мм.гггг",
        leapday: "-02-29",
        alias: "tt.mm.jjjj"
    });

    $('.phoneMask').inputmask("+7 (999) 999-9999");
    $('.emailMask').inputmask({
        mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.]V{1,4}",
        greedy: false,
        onBeforePaste: function (pastedValue, opts) {
            pastedValue = pastedValue.toLowerCase();
            console.log(pastedValue)
          },
        definitions: {
            'V': {
                validator: "[0-9A-Za-z]",
                casing: "lower"
            },
            '*': {
                validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
                casing: "lower"
            }
        }
      });


    $('.numberMask').inputmask("numeric");

    console.log($.fn.datepicker);
    // $('#datepicker-winners').datepicker();


    $('.menu').css('transition', '0.5s');



    $("#prize_filter").change(function () {
        prize_filter();
    });
    
    $("#date_filter").change(function () {
        prize_filter();
    });

    // var splitedDate = $('#date_filter').val().split('.');
    // var newDate = new Date(Number(splitedDate[2]), Number(splitedDate[1]), Number(splitedDate[0]));

    $('#winnersDate').datepicker({
        // startDate: newDate,
        onSelect: function (dateText, inst) {
            $('#date_filter').val(dateText);
            $('#date_filter').trigger('change');
        },
    });

    // var winnersdp = $('#winnersDate').datepicker().data('datepicker');
    // winnersdp.selectDate(newDate);

});

$(window).resize(function() {
    toNailFooter();
});



$('[data-openPopup]').on('click', function() {
    var that = $(this);
    var target = that.attr('data-openPopup');

    if (target == "check" && $('body').attr('data-auth') == 0) {
        target = "auth"
    }

    if (that.attr('data-openmanual') == 'true') {
        openPopup(target, true);
        return false;
    }

    openPopup(target);
});

$('[data-closePopup]').on('click', function() {
    var that = $(this);
    var target = that.attr('data-closePopup');

    closePopup(target);
});

$('.popups__overlay').on('click', function() {
    closePopupAll();
});


$('form').on('submit', function() {
    event.preventDefault();

    var context = $(this);

    context.find('input.obligatory').each(function(index, input) {
        var that = $(input);
        var name = that.attr('name');

        if (that.attr('type') == 'text' && that.val() == '') {
            $(input).addClass('error');
            context.find('.input-error.' + name + '').css('opacity', '1');
        } else if (that.attr('type') == 'password' && that.val() == '') {
            $(input).addClass('error');
            context.find('.input-error.' + name + '').css('opacity', '1');
        } else if (that.attr('type') == 'checkbox' && that.is(':checked') == false) {
            $(input).addClass('error');
            context.find('.input-error.' + name + '').css('opacity', '1');
        }
    })
});

$('input').on('input', function() {
    var that = $(this);
    var name = that.attr('name');
    var context = that.parents('form');

    that.removeClass('error');
    context.find('.input-error.' + name + '').css('opacity', '0');
});


$('.customSelect').on('click', function () {
    $(this).toggleClass('dropdown-opened');
    $(this).find('.customSelect__dropdown').slideToggle();
});

$('.winners').find('.customSelect__dropdown_item').on('click', function () {
    var that = $(this);
    var context = that.parents('.winners__selector');
    var text = that.text();
    var value = that.attr('data-value');
    context.find('.customSelect').attr('data-value', value);
    context.find('.customSelect__window_text').text(text);
});



$('.headerMenu__buttons_item-burger').on('click', function() {
    $(this).toggleClass('opened');
    $('.menu').toggleClass('opened');

    if ($(this).hasClass('opened')) {
        stopScroll.scroll(false);
    } else {
        stopScroll.scroll(true);
    }
});

$('.content, footer').click(function (e){
    var div = $(".menu");

    if (div.hasClass('opened')) {
        if (!div.is(e.target) && div.has(e.target).length === 0) {
            div.removeClass('opened');
            $('.headerMenu__buttons_item-burger').removeClass('opened');
            stopScroll.scroll(true);
        }
    }
});



$('.winterMay__arrowDown').on('click', function() {
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".winterMay__tablet.tablet-left").offset().top
    }, 2000);
});

$('.regForm').on('submit', function() {
    event.preventDefault();

    var that = $(this);

    if (that.find('#reg_pass').val() != that.find('#reg_pass2').val()) {
        openPopupSystem('Ошибка!', 'Пароли не совпадают');
        return false;
    }

    var phone = $('#reg_phone').inputmask('unmaskedvalue').replace(' ', '').replace('-', '');

    if (that.find('.error').length == 0) {
        send_phone_by_code(phone);
    }
    
});

$('.smsForm').on('submit', function() {
    event.preventDefault();

    var that = $(this);

    var code = $('#smsCode').val();

    reg_member(code);
});

$('.infoForm').on('submit', function() {
    var that = $(this);

    setTimeout(function() {
        if (that.find('.error').length == 0) {
            update_member();
        }
    }, 100)
});

$('.authForm').on('submit', function() {
    var that = $(this);

    setTimeout(function() {
        if (that.find('.error').length == 0) {
            auth_member();
        }
    }, 100)
});


$('.resPassForm').on('submit', function() {
    var that = $(this);

    setTimeout(function() {
        if (that.find('.error').length == 0) {
            pwd_member();
        }
    }, 100)
});


$('.checkForm').on('submit', function() {
    var that = $(this);

    setTimeout(function() {
        if (that.find('.error').length == 0) {
            check_registration();
        }
    }, 100)
});

$('div[filterwinners]').on('click', function() {
    var that = $(this);
    var id = that.attr('data-value');

    $('#prize_filter').val(id);
    $('#prize_filter').trigger('change');
})

$('.textOnly').on('keypress', function() {
    var that = this;
    
    setTimeout(function() {
      var res = /[^а-яА-ЯїЇєЄіІёЁa-zA-Z ]/g.exec(that.value);
      that.value = that.value.replace(res, '');
    }, 0);
});

$('.checkForm__input_help').on('click', function() {
    var that = $(this);
    var target = that.attr('data-item');

    $('.checkBlock__img .helper').hide();
    $('.checkBlock__img').find('.helper[data-item="' + target + '"]').show();
});

$('.checkForm__input_help').hover(function() {
    var that = $(this);
    var target = that.attr('data-item');

    $('.checkBlock__img .helper').hide();
    $('.checkBlock__img').find('.helper[data-item="' + target + '"]').show();
}, function() {
    $('.checkBlock__img .helper').hide();
});

$('.resendSms').on('click', function() {
    var phone = $('#reg_phone').inputmask('unmaskedvalue').replace(' ', '').replace('-', '');
    send_phone_by_code(phone);
})

$('.statusUpload').find('input').change(function() {
    var that = $(this);
    var context = that.parents('.cabinet__table_row');
    var id = context.attr('data-id');
    var image = that[0].files[0];

    check_image_upload(id, image);
})

$('.qrButton').on('click', function() {
    qrInit();
})

var qrScanner = null;

function qrInit() {
    $('.qrHideBlock').hide();
    $('.qrShowBlock').show();
    var videoElem = document.getElementById('qrVideo');

    qrScanner = new QrScanner(videoElem, result => check_registration(result), 220);
    console.log(qrScanner);
    //qrScanner = new QrScanner(videoElem, result => alert(result), 220);
    qrScanner.start();
}

window.qrKill = () => {
    qrScanner.destroy();
    qrScanner = null;

    $('.qrShowBlock').hide();
    $('.qrHideBlock').show();
}

// window.getSearchUrlObject = (search) => {
//     if (search === "") return false;
//     return JSON.parse('{"' + decodeURI(search.replace(/&/g, '","').replace(/=/g, '":"')) + '"}');
// }


// $('#qrCode').change(function() {
//     var that = $(this);

//     check_registration(that.val());
// })